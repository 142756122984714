@keyframes line_about_1 {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: calc(var(--height) * var(--ratio));
  }
}

@keyframes line_about_2 {
  from {
    background-position-x: calc(var(--height) * var(--ratio));
  }

  to {
    background-position-x: 0;
  }
}

.about {
  // position: static;
  // &.page {
  //   background: rgba(0, 0, 0, .3);
  // }

  &__button {
    @extend .home__button;

    &s {
      @extend .home__buttons;

      top: 0;
      bottom: auto;
    }
  }

  .page__inner {
    max-width: rem(1300);
    margin: auto;
    padding-top: 1px;
  }

  &__header {
    margin-top: rem(170);
    height: rem(350);
    mix-blend-mode: difference;
    pointer-events: none;
    position: relative;
    transform: translateZ(0);

    &-inner {
      position: fixed;
      left: 50%;
      margin-left: -50vw;
      width: 100vw;
      height: 100%;
      overflow: hidden;
      pointer-events: none;
    }

    &-line {
      --height: #{rem(160)};
      --ratio: 1;

      display: block;
      height: var(--height);
      position: absolute;
      left: 0;
      white-space: nowrap;
      // animation: line_about_1 5s linear;
      // animation-iteration-count: infinite;
      // contain: paint;
      width: 100%;
      background-size: auto 100%;
      background-repeat: repeat-x;

      &:first-child {
        top: 0;
      }

      &:last-child {
        bottom: 0;
        animation-name: line_about_2;
      }
    }

    &-logo {
      display: none;
      height: 100%;
      width: auto;
      margin: 0 rem(20);
    }
  }

  &__link {
    @extend .button-style;

    text-transform: lowercase;

    &s {
      display: flex;
      justify-content: center;
      margin-top: rem(100);
      margin-bottom: rem(90);
    }

    &:last-child {
      margin-left: rem(-10);
    }
  }

  &__info {
    text-align: center;

    &-big {
      font-size: rem(43);
      line-height: 1.09;
      text-transform: uppercase;
    }

    &-medium {
      margin-top: rem(65);
      font-size: rem(36);
      line-height: 1.35;
    }

    &-small {
      margin-top: rem(100);
      padding-bottom: rem(200);
      font-size: rem(18);
      line-height: rem(28);
      display: flex;
      text-align: left;

      &-inner {
        $margin: rem(60);

        flex-basis: calc(50% - #{math.div($margin, 2)});

        & + & {
          margin-left: $margin;
        }
      }
    }
  }
}

@include tablet-portrait {
  @keyframes line_about_1 {
    from {
      background-position-x: 0;
    }

    to {
      background-position-x: calc(var(--height) * var(--ratio));
    }
  }

  @keyframes line_about_2 {
    from {
      background-position-x: calc(var(--height) * var(--ratio));
    }

    to {
      background-position-x: 0;
    }
  }

  .about {
    &__button {
      &s {
        left: 0;
        justify-content: space-between;
        flex-direction: row-reverse;
      }
    }

    &__header {
      margin-top: rem(90);
      margin-bottom: rem(30);
      height: rem(130);

      &-line {
        --height: #{rem(55)};

        height: var(--height);
      }
    }

    &__link {
      &s {
        position: fixed;
        right: $page-padding-m + rem(40) + rem(10);
        top: $page-padding-m;
        margin: 0;
      }

      &:last-child {
        margin-left: rem(10);
      }
    }

    &__info {
      padding: 0 rem(30);

      &-big {
        font-size: rem(16);
        line-height: 1.4;
      }

      &-medium {
        margin-top: rem(20);
        font-size: rem(16);
        line-height: 1.3;
        flex-direction: column;
        text-align: center;
      }

      &-small {
        margin-top: rem(30);
        padding-bottom: rem(40);
        font-size: rem(14);
        line-height: 1.3;
        flex-direction: column;
        text-align: center;

        &-inner {
          flex-basis: auto;

          & + & {
            margin-left: 0;
            margin-top: rem(20);
          }
        }
      }
    }
  }
}
