.spacer {
  flex: 1;
}

.cursor {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  pointer-events: none;
  will-change: transform;

  &::after {
    content: '';
    height: 8px;
    width: 8px;
    background: $white;
    display: block;
    position: relative;
    left: -4px;
    top: -4px;
    border-radius: 50%;
    transition: transform .4s $cubic-ease-out;
  }

  &.hover {
    &::after {
      transform: scale(2.5);
      transition-duration: .2s;
    }
  }

  &.mousedown {
    &::after {
      transform: scale(.8) !important;
      transition-duration: .2s;
    }
  }

  @include tablet-portrait {
    display: none;
  }
}

.wipe-block {
  & {
    contain: layout style paint;
  }
}

.parralax-block {
  .not-desktop & {
    transform: none !important;
  }
}

.absolute-hidden {
  position: absolute;
  left: -100vw;
  top: -100vh;
}

.back_to_top {
  display: inline-block;
  margin-top: rem(100px);
  font-size: rem(60px);
  letter-spacing: rem(2.11px);
  text-transform: uppercase;
  line-height: .95em;
  position: relative;

  @include hover {
    &:hover {
      &::before {
        transform: translateY(-150%) scale(1.2);
        opacity: 1;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    transform: translateY(-100%);
    top: rem(-5);
    left: rem(3);
    display: block;
    width: rem(79);
    height: rem(45);
    background-image: url(/../assets/svg/arrow_top.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90% auto;
    @include transition('transform', .3s, $cubic-ease-out);
  }

  @include tablet-portrait {
    font-size: rem(28px);
    letter-spacing: rem(1);
    margin-top: rem(60px);

    &::before {
      top: rem(-5);
      left: rem(0);
      display: block;
      width: rem(30);
      height: rem(16);
    }
  }
}

.panel {
  position: fixed;
  z-index: 999;
  transform-origin: center;
  // transform: translateX(-100%);
  pointer-events: none;
  backface-visibility: hidden;
  background: var(--color2);
  width: 150vmax;
  height: 150vmax;
  margin-left: -75vmax;
  margin-top: -75vmax;
  top: 50%;
  left: 50%;
  contain: strict;
  // transform: translate(-50%, -50%);
  border-radius: 50%;
  will-change: transform;

  &:nth-child(1), &:nth-child(4) {
    background: $black;
  }

  &:nth-child(2) {
    background: var(--color2);
    background-size: auto 70vh;
    background-position: center;
    background-repeat: no-repeat;

    @include tablet-portrait {
      background-size: 70vw auto;
    }
  }

  &:nth-child(3) {
    background: var(--color1);
  }
}

.clear-margin {
  margin: -1em 0;
}

.reveal-block {
  // opacity: 0;
  // transform: translateY(5vh);
  @include transition('opacity, transform, object-fit', .8s, $sine-ease-out);

  .show-page &.revealed {
    // opacity: 1;
    // transform: none;
  }
}

.underlined-button {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -.05em;
    left: 0;
    width: 100%;
    height: .05em;
    background: var(--text-color);
    transform: scaleX(0);
    transition: transform .25s $quart-ease-in-out;
    transform-origin: right;
    backface-visibility: hidden;
  }

  .desktop &:hover, &.current {
    &::after {
      transform: scaleX(1.001);
      transform-origin: left;
    }
  }

  &.white {
    color: $white;

    &::after {
      background: $white;
    }
  }

  &.inverted {
    &::after {
      transform: scaleX(1.001);
      transform-origin: left;
    }

    .desktop &:hover {
      &::after {
        transform: scaleX(0);
        transform-origin: right;
      }
    }
  }
}
