.background {
  &__canvas {
    z-index: 0;
    outline: none;
    pointer-events: none;
    @include fullscreen(fixed);
  }

  &__ruler {
    display: none;
    outline: none;
    pointer-events: none;
    position: fixed;
    z-index: -999;
    opacity: 0;
    width: 0;
    height: 100vh;

    .ios & {
      display: block;
    }
  }

  &__lottie {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    pointer-events: none;
    mix-blend-mode: difference;
    visibility: hidden;
  }
}
