.button-style {
  position: relative;
  font-size: rem(18);
  height: rem(40);
  border-radius: rem(20);
  border: solid 1px var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 rem(17) rem(3);
  line-height: 1;

  * {
    pointer-events: none;
  }

  @include hover {
    &:hover {
      border: solid 1px $green;
      color: $green;
    }
  }

  @include tablet-portrait {
    user-select: none;
  }
}

.button-mobile-style {
  @include tablet-portrait {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(40);
    height: rem(40);
    border-radius: 50%;
    border: solid 1px $white;
    font-size: rem(18);
  }

  @include tablet-portrait {
    &.projects-icon, &.about-icon, &.insta-icon, &.email-icon {
      overflow: hidden;
      white-space: nowrap;
      text-indent: 99em;

      &::after {
        text-indent: 0;
        display: flex;
        content: '';
        justify-content: center;
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        @include fullscreen;
      }
    }

    &.projects-icon {
      &::after {
        background-image: url(/assets/svg/menu.svg);
        background-size: auto rem(13);
      }
    }

    &.insta-icon {
      &::after {
        background-image: url(/assets/svg/instagram.svg);
        background-size: auto rem(20);
      }
    }

    &.email-icon {
      &::after {
        // background-image: url(/assets/svg/mail.svg);
        // background-size: auto rem(20);
        content: '@';
        font-size: 1.1em;
        margin-top: -2px;
        font-weight: 100;
      }
    }

    &.about-icon {
      &::after {
        content: '?';
      }
    }
  }
}
