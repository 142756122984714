$quad-ease-in: cubic-bezier(.55, .085, .68, .53);
$cubic-ease-in: cubic-bezier(.55, .055, .675, .19);
$quart-ease-in: cubic-bezier(.895, .03, .685, .22);
$quint-ease-in: cubic-bezier(.755, .05, .855, .06);
$sine-ease-in: cubic-bezier(.47, 0, .745, .715);
$expo-ease-in: cubic-bezier(.95, .05, .795, .035);
$circ-ease-in: cubic-bezier(.6, .04, .98, .335);

$quad-ease-out: cubic-bezier(.25, .46, .45, .94);
$cubic-ease-out: cubic-bezier(.215, .61, .355, 1);
$quart-ease-out: cubic-bezier(.165, .84, .44, 1);
$quint-ease-out: cubic-bezier(.23, 1, .32, 1);
$sine-ease-out: cubic-bezier(.39, .575, .565, 1);
$expo-ease-out: cubic-bezier(.19, 1, .22, 1);
$circ-ease-out: cubic-bezier(.075, .82, .165, 1);

$quad-ease-in-out: cubic-bezier(.455, .03, .515, .955);
$cubic-ease-in-out: cubic-bezier(.645, .045, .355, 1);
$quart-ease-in-out: cubic-bezier(.77, 0, .175, 1);
$quint-ease-in-out: cubic-bezier(.86, 0, .07, 1);
$sine-ease-in-out: cubic-bezier(.445, .05, .55, .95);
$expo-ease-in-out: cubic-bezier(1, 0, 0, 1);
$circ-ease-in-out: cubic-bezier(.785, .135, .15, .86);

// -----
// fonts
// -----

$main-fontname : 'Object Sans Regular';
$main-font : $main-fontname, sans-serif;

@use 'sass:math';

$header-animation: .3s $quad-ease-out;

@function rem($pixel) {
  @return math.div(strip-unit($pixel), strip-unit($rem-reference)) * 1rem;
}

@function sqrt($r) {
  $x0: 1;
  $x1: $x0;

  @for $i from 1 through 10 {
    $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
    $x0: $x1;
  }

  @return $x1;
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, $number * 0 + 1);
  }

  @return $number;
}

@function parralax-depth-correct($translateZ, $perpective : 1px) {
  @return 1 + ($translateZ * -1) / $perpective;
}

@function explode($string,$dem) {
  $list: ();
  $sum: str-length($string);

  @for $i from 1 through $sum {
    $str: str-index($string, $dem);

    @if str-length($string) >= 1 and $str == null {
      $list: append($list, $string);
      $string: '';
    }

    @if type-of($str) == number {
      $each: str-slice($string, 0, ($str - 1));
      $list: append($list, $each);
      $string: str-slice($string, ($str + 1), $sum);
    }
  }

  @return $list;
}

@mixin background-image-retina($file, $type, $width, $height) {
  background-image: url($file + '.' + $type);
  background-size: $width $height;
  background-repeat: no-repeat;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 144dpi) {
    & {
      background-image: url($file + '@2x.' + $type);
    }
  }
}

@mixin transition($properties :'visibility, opacity', $duration:.5s, $ease:$quad-ease-out, $delay:0s) {
  $value: '';
  $list: explode($properties, ',');

  @each $property in $list {
    @if (value != '') {
      $value: $value +', ';
    }

    $value: $value+'#{$property} #{$duration} #{$ease} #{$delay}';
  }

  transition: #{$value};
}

@mixin on-line-text-no-wrap() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin ellipsis($lines-to-show, $font-size, $line-height :1) {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  line-height: $line-height;
  max-height: $font-size*$lines-to-show*$line-height;
  font-size: $font-size;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin fullscreen( $position: absolute ) {
  position: $position;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin fullscreen-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin fullscreen-cover() {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin fullscreen-contain() {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin vertical-align-children($target : '*') {
  .flexbox & {
    display: flex;
    align-items: center;
  }

  .no-flexbox & > #{$target} {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
  }
}

@mixin center-children($target : '*', $modernizrAddons:'') {
  #{$modernizrAddons}.flexbox & {
    display: flex;
    align-items: center;
    justify-content: center;
    -moz-box-align: center;
  }

  #{$modernizrAddons}.no-flexbox & > #{$target} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin fade-transition($show : true, $selector : '&.show') {
  @include transition;

  @if ($show) {
    opacity: 0;
    visibility: hidden;
  } @else {
    opacity: 1;
    visibility: visible;
  }

  #{$selector} {
    @if ($show) {
      opacity: 1;
      visibility: visible;
    } @else {
      opacity: 0;
      visibility: hidden;
    }
  }
}

@mixin box-shadow($distance :5px, $opacity : .5) {
  box-shadow: 0 0 $distance rgba(0, 0, 0, $opacity);
}

@mixin text-shadow($distance :5px, $color : rgba(0, 0, 0, .5)) {
  text-shadow: 0 0 $distance $color;
}

@mixin filter-shadow($distance : 2px, $opacity : .3) {
  filter: drop-shadow(0 0 $distance rgba(0, 0, 0, $opacity));
}

@mixin css-parralax($translateZ, $perspective: 1px) {
  transform: translateZ($translateZ) scale(parralax-depth-correct($translateZ, $perspective));
}

@mixin justify-one-line() {
  text-align: justify;

  &::after {
    content: '';
    display: inline-block;
    width: 100%;
  }
}

@mixin mix-blend-mode() {
  @supports (mix-blend-mode: exclusion) {
    @content;
  }
}


$tablet-max-width: 1024px;
$tablet-portrait-max-width: 900px;
$phone-max-width: 740px;
$phone-portrait-max-width: 420px;


/* stylelint-disable */
:export {
  tabletMaxWidth: $tablet-max-width;
  tabletPortraitMaxWidth: $tablet-portrait-max-width;
  phoneMaxWidth: $phone-max-width;
  phonePortraitMaxWidth: $phone-portrait-max-width;
}
/* stylelint-enable */


@mixin small() {
  @media all and (max-width: 1200px) {
    @content;
  }
}

@mixin medium() {
  @media all and (max-width: 1400px) {
    @content;
  }
}

@mixin tablet() {
  @media all and (max-width: #{$tablet-max-width}) {
    @content;
  }
}

@mixin not-tablet() {
  @media all and (min-width: #{$tablet-max-width + 1px}) {
    @content;
  }
}

@mixin tablet-portrait() {
  @media screen and (max-width: #{$tablet-portrait-max-width}) {
    @content;
  }
}

@mixin not-tablet-portrait() {
  @media screen and (min-width: #{$tablet-portrait-max-width + 1px}) {
    @content;
  }
}

@mixin phone() {
  @media screen and (max-width: #{$phone-max-width}) {
    @content;
  }
}

@mixin phone-portrait() {
  @media screen and (max-width: #{$phone-portrait-max-width}) {
    @content;
  }
}

@mixin not-phone() {
  @media screen and (min-width: #{$phone-portrait-max-width + 1}) {
    @content;
  }
}

@mixin hover() {
  @media screen and (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin touch() {
  @media screen and (hover: none) {
    @content;
  }
}

@mixin hover-active() {
  @include hover() {
    &:hover {
      @content;
    }
  }
  @include touch() {
    &:active {
      @content;
    }
  }
}

/* stylelint-disable color-no-hex */

// -------
// colours
// -------

$white: #fff;
$green: #b8ff00;
$black: #000;
$dark: #333;

$black-text: $black;

// -------
// sizes
// -------

$rem-reference: 1px;
$px-reference: 16px;

$original-site-max-width: 1024px;
$site-max-width: 1480px;
$site-small-max-width: 1180px;

$page-padding: rem(40);
$page-padding-m: rem(20);

// -------
// transition
// -------

@mixin basic-transition() {
  @include transition('opacity, color', .2s, $sine-ease-out);
}


$tablet-max-width: 1024px;
$tablet-portrait-max-width: 900px;
$phone-max-width: 740px;
$phone-portrait-max-width: 420px;


/* stylelint-disable */
:export {
  tabletMaxWidth: $tablet-max-width;
  tabletPortraitMaxWidth: $tablet-portrait-max-width;
  phoneMaxWidth: $phone-max-width;
  phonePortraitMaxWidth: $phone-portrait-max-width;
}
/* stylelint-enable */


@mixin small() {
  @media all and (max-width: 1200px) {
    @content;
  }
}

@mixin medium() {
  @media all and (max-width: 1400px) {
    @content;
  }
}

@mixin tablet() {
  @media all and (max-width: #{$tablet-max-width}) {
    @content;
  }
}

@mixin not-tablet() {
  @media all and (min-width: #{$tablet-max-width + 1px}) {
    @content;
  }
}

@mixin tablet-portrait() {
  @media screen and (max-width: #{$tablet-portrait-max-width}) {
    @content;
  }
}

@mixin not-tablet-portrait() {
  @media screen and (min-width: #{$tablet-portrait-max-width + 1px}) {
    @content;
  }
}

@mixin phone() {
  @media screen and (max-width: #{$phone-max-width}) {
    @content;
  }
}

@mixin phone-portrait() {
  @media screen and (max-width: #{$phone-portrait-max-width}) {
    @content;
  }
}

@mixin not-phone() {
  @media screen and (min-width: #{$phone-portrait-max-width + 1}) {
    @content;
  }
}

@mixin hover() {
  @media screen and (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin touch() {
  @media screen and (hover: none) {
    @content;
  }
}

@mixin hover-active() {
  @include hover() {
    &:hover {
      @content;
    }
  }
  @include touch() {
    &:active {
      @content;
    }
  }
}
