.page {
  color: var(--text-color);
  opacity: .0001;
  max-width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // z-index: 1;

  &__content {
    // max-width: rem($site-max-width);
    margin: auto;
  }

  &.appearing {
    pointer-events: none;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

@include tablet-portrait () {
  .page {
  }
}
