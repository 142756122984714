.projects {
  z-index: 10;
  overflow: auto;
  // background: rgba(0, 0, 0, .45);

  &__button {
    @extend .about__button;

    &s {
      @extend .about__buttons;

      position: absolute;
    }
  }

  &__list {
    width: 100%;
    padding: 0 $page-padding;
    padding-top: rem(120);
    padding-bottom: $page-padding;
    position: relative;
    z-index: 10;
  }

  &__item {
    font-size: rem(34);
    line-height: .65;
    border-bottom: 1px solid $white;

    &-link {
      padding: $page-padding 0;
      display: flex;
      align-items: center;

      * {
        pointer-events: none;
      }
    }

    &-title {
      flex-grow: 1;
    }

    &-category {
      text-align: right;
    }

    &-duration {
      visibility: hidden;
    }

    span + span {
      margin-left: rem(70);
    }

    @include hover {
      &:hover {
        color: $green;
        border-bottom: 1px solid $green;

        .projects__item-duration {
          visibility: visible;
        }
      }
    }
  }

  &__images {
    @include fullscreen(fixed);
  }

  &__image {
    $size: rem(720);
    @include fullscreen();

    display: none;
    justify-content: center;
    align-items: center;

    &.visible {
      display: flex;
    }

    img {
      object-fit: contain;
      width: $size;
      height: $size;
      max-height: 80vh;
    }
  }
}

@include tablet-portrait {
  .projects {
    &__button {
      &s {
        left: 0;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &__list {
      padding: 0 $page-padding-m;
      padding-top: rem(80);
      padding-bottom: $page-padding-m;
    }

    &__item {
      font-size: rem(18);
      line-height: .65;

      &-link {
        padding: $page-padding-m 0;
      }

      &-category {
        font-size: rem(12);
        line-height: 1em;
      }

      &-title {
        line-height: rem(20);
      }

      &-duration {
        display: none;
      }

      span + span {
        margin-left: 0;
      }
    }

    &__images {
      display: none;
    }
  }
}
