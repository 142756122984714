.home {
  &__link {
    display: block;
    cursor: none;
    @include fullscreen(fixed);

    &-hold {
      @extend .button-style;

      display: none;
    }
  }

  &__logos {
    @include fullscreen(fixed);

    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    mix-blend-mode: difference;
    transform: translateZ(0);

    // z-index: 20;

    &-inner {
      width: rem(1000);
      max-width: 90vw;
    }
  }

  &__cursor {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    padding: rem(15) rem(20);
    font-size: rem(18);
    line-height: 1.22;
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s $sine-ease-out;

    &.visible {
      opacity: 1;
    }

    &-wrapper, &-cross {
      position: absolute;
      width: 1px;
      height: 0;
      left: 0;
      top: 0;
    }

    &-cross {
      &::after, &::before {
        width: rem(2);
        height: rem(36);
        content: '';
        display: block;
        background: $white;
        position: absolute;
        left: 0;
      }

      &::before {
        top: rem(10);
      }

      &::after {
        bottom: rem(10);
      }

      &:first-child {
        transform: rotate(91deg);
        transform-origin: center;
      }
    }
  }

  &__button {
    @extend .button-style;

    text-transform: lowercase;

    &:last-child {
      margin-left: rem(-10);
    }

    &s {
      position: fixed;
      right: 0;
      left: 0;
      bottom: 0;
      margin: $page-padding;
      display: flex;
      z-index: 50;
    }
  }
}

@include touch {
  .home {
    &__link {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: rem(44);

      &-hold {
        left: 0;
        display: flex;
        position: relative;
        overflow: hidden;
        transform: translateZ(0);

        &-wrapper {
          display: block;
          background-color: $white;
          overflow: hidden;
          border-radius: inherit;
          transform: translateX(-100%);
          @include fullscreen;
        }

        &-inner {
          transform: translateX(100%);
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $dark;
        }

        &-inner, &-wrapper {
          transition: transform .3s $quad-ease-out;
        }
      }

      &.hold &-hold {
        &-inner, &-wrapper {
          transform: none;
          transition: transform 1s $quad-ease-out;
        }
      }
    }
  }
}

@include tablet-portrait {
  .home {
    &__logos {
      width: auto;
      height: auto;

      // z-index: 20;

      &-inner {
        margin: rem(-5) rem(20);
        width: rem(200);
      }
    }

    &__cursor {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: rem(22);
      text-align: justify;
      opacity: 1 !important;
      transform: none !important;

      &-wrapper {
        display: none;
      }

      .letter {
        display: inline-block;
        width: rem(17);
        text-align: center;
      }
    }

    &__button {
      &:last-child {
        margin-left: rem(10);
      }

      &s {
        top: 0;
        right: 0;
        left: initial;
        bottom: initial;
        margin: $page-padding-m;
      }
    }
  }
}
