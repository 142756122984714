.video {
  &__video {
    display: none;
  }

  &__controls {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: $page-padding;
    display: flex;
    align-items: center;
  }

  &__play, &__pause {
    @extend .button-style;

    width: rem(95);
  }

  &__pause {
    display: none;
  }

  .playing & {
    &__pause {
      display: block;
    }

    &__play {
      display: none;
    }
  }

  &__timeline {
    flex-grow: 1;
    height: rem(28);
    margin: 0 rem(20);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      position: absolute;
      // background: rgba(255, 255, 255, .2);
      background: var(--text-color);
      opacity: .2;
      height: 1px;
      top: 50%;
      left: 0;
      right: 0;
    }

    &-inner {
      background: var(--text-color);
      height: 1px;
      width: 100%;
      position: relative;
      transform: scaleX(0);
      transform-origin: left;
    }

    &-cursor {
      display: block;
      right: 0;
      top: 0;
      height: 100%;
      width: 100%;
      border-right: 1px solid var(--text-color);
      position: absolute;
    }

    @include hover {
      &:hover &-cursor {
        border-right-color: $green;
      }
    }
  }

  &__timecode {
    font-size: rem(28);
    width: rem(230);
  }
}

@include tablet-portrait {
  .video {
    &__controls {
      padding: $page-padding-m;
      justify-content: space-between;
    }

    &__play, &__pause {
      width: rem(95);
    }

    &__timeline {
      display: none;
    }

    &__timecode {
      text-align: right;
      font-size: rem(25);
      width: auto;

      .letter {
        display: inline-block;
        width: rem(17);
        text-align: center;
      }
    }
  }
}
