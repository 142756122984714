.photos {
  @include fullscreen(fixed);

  &__list {
    width: 100%;
    height: 100%;
    display: flex;
    // background: $black;
  }

  &__arrow {
    @extend .button-style;

    position: absolute;
    top: 50%;
    z-index: 50;
    transition: opacity .3s $sine-ease-out;

    &.disable {
      opacity: .5;
      pointer-events: none;
    }
  }

  &__prev {
    left: $page-padding;
  }

  &__next {
    right: $page-padding;
  }

  &__item {
    flex-basis: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    img {
      $size: rem(920);

      pointer-events: none;
      object-fit: contain;
      width: $size;
      height: $size;
      max-height: 80vh;
    }
  }
}

@include tablet-portrait {
  .photos {
    &__arrow {
      top: initial;
      bottom: $page-padding-m;
    }

    &__prev {
      left: $page-padding-m;
    }

    &__next {
      right: $page-padding-m;
    }

    &__item {
      img {
        pointer-events: none;
        object-fit: contain;
        width: 80%;
        height: 80%;
        max-height: 80vh;
      }
    }
  }
}
