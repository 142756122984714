.page.dark {
  --text-color: #{$black};
}

.project {
  &__header {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    margin: $page-padding;
    display: flex;
    justify-content: space-between;
    z-index: 30;

    &-info {
      width: rem(40);

      &::before {
        content: 'i';
      }
    }

    &-info, &-back {
      @extend .button-style;
    }

    &.open-info {
      .project__header {
        &-info {
          // opacity: 0;
          &::before {
            content: '-';
          }
        }
      }

      .project__info {
        display: block;
      }
    }
  }

  &__info {
    display: none;
    font-size: rem(24);
    line-height: 1.25;
    text-align: right;
    width: 30%;
    position: absolute;
    right: 0;
    top: rem(60);
    pointer-events: none;

    &-title {
      font-size: inherit;
      margin-bottom: rem(50);
    }

    &-small {
      margin-top: rem(30);
      font-size: rem(18);
      line-height: 1.33;
    }
  }
}

@include tablet-portrait {
  .project {
    &__header {
      margin: $page-padding-m;
    }

    &__info {
      font-size: rem(16);
      width: rem(230);

      &-title {
        margin-bottom: rem(25);
      }

      &-small {
        margin-top: rem(10);
        font-size: rem(11);
      }
    }
  }
}
